import IconsCustomerCare from '~icons/icons/CustomerCare.svg'
import IconsChat from '~icons/icons/Chat.svg'
import IconsMail from '~icons/icons/Mail.svg'
import IconsWhatsapp from '~icons/icons/Whatsapp.svg'
import IconsMessenger from '~icons/icons/Messenger.svg'
import IconsHelp from '~icons/icons/Help.svg'
import IconsBookStore from '~icons/icons/BookAppointment.svg'

export type IconType = keyof typeof iconsMap

export const iconsMap = {
  phone: IconsCustomerCare,
  chat: IconsChat,
  email: IconsMail,
  whatsapp: IconsWhatsapp,
  messenger: IconsMessenger,
  store: IconsBookStore,
  other: IconsHelp,
}
